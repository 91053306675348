import React from 'react';

import {Layout, SEO} from '../components';

export const ErrorPage = (): JSX.Element => {
  return (
    <Layout>
      <SEO title="404: Not found" pathname="/not_found" />
      <h1 className="font-bold text-gray-500 mt-3 mb-2 max-w-7xl mx-auto">Not Found</h1>
      <div className="max-w-7xl mx-auto">
        You just hit a route that doesn&#39;t exist... the sadness.
      </div>
    </Layout>
  );
};

export default ErrorPage;
